<template>
  <div class="service">
    <van-nav-bar
      :title="$t('CUSTOMERSERVICECENTER')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <van-grid :column-num="2">
      <van-grid-item @click="toUrl1()" class="borright">
        <div slot="icon">
          <van-image class="boximg" :src="lineimg" />
        </div>
        <div slot="text" class="text" >{{name1}}</div>
      </van-grid-item>
      <!-- <van-grid-item @click="toUrl2()">
        <div slot="icon">
          <van-image class="boximg" :src="loadming" />
        </div>
        <div slot="text" class="text" >{{name2}}</div>
      </van-grid-item> -->
    </van-grid>
    <div class="partition">{{$t('QUESTION')}}</div>
    <div class="container">
      <van-collapse v-model="activeNames">
        <van-collapse-item v-for="(item, index) in list" :key="index" :title="item.title" :name="item.id">{{item.context}}</van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
// import '../My/css/my.css'
import './css/index.css'
// import { ref } from 'vue'
import { getList, getContactConfig } from '@/api/kefu'
export default {
  name: 'service',
  data () {
    return {
      activeNames: ['2'],
      list: [],
      name1: '',
      url1: '',
      name2: '',
      url2: '',
      lineimg: require('./img/LineMessenger.png'),
      loadming: require('./img/loader.png')
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    async loadgetList () {
      try {
        const { data } = await getList()
        if (data.code === 200) {
          // console.log(data)
          this.list = data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async loadgetContactConfig () {
      try {
        const { data } = await getContactConfig({
          url: document.domain
        })
        if (data.code === 200) {
          console.log(data)
          this.name1 = data.data.name1
          this.url1 = data.data.url1
          this.name2 = data.data.name2
          this.url2 = data.data.url2
        }
      } catch (error) {
        console.log(error)
      }
    },
    toUrl1 () {
      location.href = this.url1
    },
    toUrl2 () {
      location.href = this.url2
    }
  },
  created () {
    this.loadgetList()
    this.loadgetContactConfig()
  }
}
</script>

<style scoped>
.right {
  flex: 1;
}
.left {
  width: 100px;
}
.boximg {
  width: 50px;
  height: 50px;
}
.text {
  font-size: 18px;
}
.borright {
  border-right: 1px solid #eee;
}
.partition {
  font-size: 16px;
  height: 35px;
  background-color: #FFF1D8;
  line-height: 35px;
  text-indent: 1em;
}
.service >>> .van-nav-bar__content {
  background-color: #FF910B;
  /* color: #fff; */
}
.service >>> .van-nav-bar__title {
  color: #fff;
}
</style>
